import {MDCSelect} from '@material/select';
import {attachObjectToMultipleElements} from "../motion/utilities/attach-obj-to-elements";

const select = document.querySelector('.mdc-select');

let mdc_select = null;

if ( select !== null ){
  mdc_select = attachObjectToMultipleElements('.mdc-select', MDCSelect, true);
}

export { mdc_select };
