import {isIdUnique} from '../utilities/element-identifiers';
import {preventSpaceScroll, preventHomeEndScroll} from '../utilities/events';
import {MotionViewport} from '../utilities/viewport';

class MotionExpander {

  constructor( container, index, idPrefix ) {

    // this.beforeInit();

    // init
    // ----------------------------------------
    this.container = container;
    this.id = this.container.getAttribute('id');

    if ( this.id === null ) {
      this.id = `${idPrefix}-${index}`;
      this.container.setAttribute( 'id', this.id );
    }

    // Get direct children with class '.mdc-expander__item'
    //  A better way would be:
    //    ```this.items = container.querySelectorAll(':scope > .mdc-expander__item')```
    //  But ```:scope``` is not IE compatible
    //  And using ```container.getElementsByClassName('mdc-expander__item')``` won't only return direct children
    //    which won't allow for nested expanders
    this.items = Array.from(this.container.children).filter( el => { return el.classList.contains('mdc-expander__item') });
    this.size = this.items.length;
    this.triggers = [];
    this.contents = [];

    for ( let i = 0; i < this.size; i++ ) {
      this.triggers[i] = this.items[i].children[0]; // first child [0] of an item is the trigger
      this.contents[i] = this.items[i].children[1]; // second child [1] of an item is the content

      // // The following implementation won't limit the results to direct children of the item
      // this.triggers[i] = (this.items[i].getElementsByClassName('mdc-expander__trigger'))[0];
      // this.contents[i] = (this.items[i].getElementsByClassName('mdc-expander__content'))[0];

      let item_id = this.items[i].getAttribute('id');

      if ( item_id === null ) {
        // Set Item ID to url-safe version of trigger's inner text
        item_id = this.triggers[i].querySelector('.mdc-expander__trigger-text')
          .innerText.trim().toLowerCase()
          .replace(/\W|\s/g, '-')
          .replace(/-+/g, '-');

        if ( ! isIdUnique( item_id ) )
          item_id = `${this.id}-${i}_${item_id}`; // Set Item ID to concatenation of expander ID with index of each item

        this.items[i].setAttribute( 'id', item_id );
      }

      this.contents[i].setAttribute('id', item_id + '_content' );
      this.contents[i].setAttribute('role', 'tabpanel');

      this.triggers[i].setAttribute('id', item_id + '_trigger' );
      this.triggers[i].setAttribute('role', 'tab');
      this.triggers[i].setAttribute('aria-expanded', 'false');
      this.triggers[i].setAttribute('aria-controls', item_id + '_content');
    }

    this.afterInit();
    this.register_observers();
  }

  // beforeInit() {}

  afterInit() {
    for ( let o = 0; o < this.size; o++ )
      this.items[o].classList[ this.contents[o].classList.contains('open') ? 'add' : 'remove' ]('mdc-expander__item--open');
  }

  // @see https://www.w3.org/TR/wai-aria-practices-1.1/#accordion
  register_observers() {
    const observers = {
      triggers : [
        {
          event : 'mousedown',
          funct : 'mousedownOnTrigger',
        },
        {
          event : 'keydown',
          funct : 'keydownOnTrigger',
        },
        {
          event : 'keyup',
          funct : 'keyupOnTrigger',
        },
      ],
      contents : [
        {
          event : 'keyup',
          funct : 'keyupOnContent',
        },
      ],
    };

    // Chrome complains about non-passive event listeners but since we're calling preventDefault
    // for touch events, Chrome's complaints are null and void

    for ( const observableSection in observers ) {
      if ( ! observers.hasOwnProperty( observableSection ))
        continue;

      const section = observers[observableSection],
            section_length = section.length;

      for ( let s = 0; s < section_length; s++ ) {
        const observation = section[s];

        // Then assign a listener for the event to each element
        for ( let e = 0; e < this.size; e++ ) {
          this[observableSection][e].addEventListener( observation.event, ((_that) => {
            return ( event ) => {
              _that[observation.funct]( event );
            };
          })(this));
        }
      }
    }

  }

  /**
   * Abstract function to be completed in sub-classes
   *
   * @param {Event} event
   */
  mousedownOnTrigger( event ) {}

  keydownOnTrigger( event ) {
    preventSpaceScroll( event.target );
    preventHomeEndScroll( event.target );
  }

  keyupOnTrigger( event ) {
    const activeIndex = this.triggers.indexOf( event.currentTarget );
    // console.log(event.target);

    switch (event.keyCode) {

      // Enter
      case 13:

      // Space
      case 32:
        this.mousedownOnTrigger(event);
        break;

      // End
      case 35:
        const lastIndex = this.size - 1;

        // If index is the last, scroll to bottom of page
        if ( lastIndex === activeIndex ) {
          event.currentTarget.blur();
          MotionViewport.scrollToBottom();
          break;
        }

        // focus on last trigger
        (this.triggers[lastIndex]).focus();
        break;

      // Home
      case 36:
        // If index is 0 scroll to top of page
        if ( activeIndex === 0 ) {
          event.target.blur();
          MotionViewport.scrollToTop();
          break;
        }

        // focus on first trigger
        (this.triggers[0]).focus();
        break;

      // ArrowUp
      case 38:
        const previousIndex = activeIndex - 1;

        // If previous index is -1 do nothing
        if (previousIndex === -1)
          break;

        this.triggers[ previousIndex ].focus();
        break;

      // ArrowDown
      case 40:
        const nextIndex = activeIndex + 1;

        // If next index is larger than expander size do nothing
        if (nextIndex === this.size)
          break;

        this.triggers[nextIndex].focus();
        break;
    }

  }

  keyupOnContent( event ) {
    // event.preventDefault();
    let item = event.target;
    // console.log( event );

    // ENHANCEMENT this loop can be taken out and into its own help function for finding parent elements
    // when the content element is reached break the loop, otherwise check the next ancestor
    while ( true ) {
      if( item.classList.contains( 'mdc-expander__content' ))
        break;
      item = item.parentElement;
    }

    const activeIndex = this.contents.indexOf( item );

    switch ( event.keyCode ) {

      // ArrowUp
      case 38:
        const previousIndex = activeIndex - 1;

        // If previous index is -1 do nothing
        if (previousIndex === -1)
          break;

        this.triggers[ previousIndex ].focus();
        break;

      // ArrowDown
      case 40:
        const nextIndex = activeIndex + 1;

        // If next index is larger than expander size do nothing
        if (nextIndex === this.size)
          break;

        this.triggers[nextIndex].focus();
        break;
    }
  }


  /**
   * @param {HTMLElement} itemElement
   * @param {string} iconText
   */
  changeIndicatorIcon( itemElement, iconText ) {
    const this_indicator = (itemElement.getElementsByClassName('mdc-expander__indicator'))[0];

    if ( typeof this_indicator !== 'undefined' )
      this_indicator.innerText = iconText;
  }

}

export { MotionExpander };
