import {MotionExpander} from './_base';
import {SlideAnimations} from "../animations/slide-animations";

class MotionRegularExpander extends MotionExpander {

  constructor( container, index ) {
    super( container, index, 'regular-expander' );
  }

  mousedownOnTrigger( event ) {
    // event.preventDefault();
    const this_trigger = event.currentTarget,
      this_item = this_trigger.parentNode,
      this_content = document.getElementById( this_item.getAttribute('id') + '_content' ); // getting element by ID is faster than by classname

    // If state before change is 'open'
    if ( this_content.classList.contains('open') ) {

      this_item.classList.add( 'closing' );
      this_item.classList.remove( 'mdc-expander__item--open' );

      this.changeIndicatorIcon( this_trigger, 'expand_more' );

      SlideAnimations.slideUp(this_content, 350, () => {
        this_item.classList.remove('closing');
      });

    } else {

      this_item.classList.add( 'opening' );

      this.changeIndicatorIcon( this_trigger, 'expand_less' );

      SlideAnimations.slideDown(this_content, 350, () => {
        this_item.classList.add( 'mdc-expander__item--open' );
        this_item.classList.remove( 'opening' );
      });

    }

  }

}

export { MotionRegularExpander };
