import {MDCDrawer} from '@material/drawer';
import {MDCTopAppBar} from '@material/top-app-bar';
import {MotionViewport} from "../motion/utilities/viewport";
/*import {motion_breadcrumbs} from "../motion/breadcrumbs/breadcrumbs";*/

const topAppBarEl = document.getElementById('motion-top-app-bar'),
  mainContentEl = document.getElementById('motion-main-content'),
  mainDrawerEl = document.getElementById('motion-main-drawer');

let topAppBar, drawer;

if ( topAppBarEl !== null && mainDrawerEl !== null ) {

  // const ourFocusTrap = util.createFocusTrapInstance(mainDrawerEl, {
  //   clickOutsideDeactivates: false,
  //   initialFocus: false, // Navigation drawer handles focusing on active nav item.
  //   escapeDeactivates: true, // Navigation drawer handles ESC.
  //   returnFocusOnDeactivate: true, // Navigation drawer handles restore focus.
  // });


  drawer = MDCDrawer.attachTo(mainDrawerEl);
  topAppBar = MDCTopAppBar.attachTo(topAppBarEl);

  // topAppBar.setScrollTarget(mainContentEl);
  topAppBar.listen('MDCTopAppBar:nav', function () {
    drawer.open = !drawer.open;
  });

  const drawerListEl = mainDrawerEl.querySelector('.mdc-list-item:not(.mdc-drawer__accordionlabel)');
  const closeDrawerBtn = document.querySelector('.mdc-drawer__close');

  drawerListEl.addEventListener('click', function () {
    drawer.open = false;

  });

  closeDrawerBtn.addEventListener('click', function () {
    drawer.open = false;

  });


  const expand_triggers = document.querySelectorAll(".mdc-drawer__accordionlabel");

  for (let i = 0; i < expand_triggers.length; i++) {

    expand_triggers[i].addEventListener("click",function (event) {


      const this_trigger = event.currentTarget;

      if (this_trigger.nextElementSibling.classList.contains("slidedown")) {


        this_trigger.nextElementSibling.classList.remove("slidedown");
        this_trigger.nextElementSibling.classList.add("slideup");
        this.querySelector(".mdc-expander__indicator").textContent = "expand_more";
      }
      else {
        if (closeAll()) {


          this_trigger.nextElementSibling.classList.add("slidedown");

          this.querySelector(".mdc-expander__indicator").textContent = "expand_less";

        }
      }

    });

  }

  let closeAll = function () {
    const expand_content = document.querySelectorAll(".mdc-drawer__accordioncontent");
    for (let i = 0; i < expand_content.length; i++) {
      /* close all panels */
      expand_content[i].classList.remove("slidedown");
      expand_content[i].classList.remove("slideup");
      expand_triggers[i].querySelector(".mdc-expander__indicator").textContent = "expand_more";
    }
    return true;
  }


  document.body.addEventListener('MDCDrawer:closed', function () {
    const hasElementToFocus = mainContentEl.querySelector('input, button');
    if( hasElementToFocus !== null )
      hasElementToFocus.focus();
  });

  window.addEventListener('scroll', function () {
    document.querySelector('.motion-topbar-logo').classList.remove('scroll-fadein');
    document.querySelector('.motion-topbar-logo').classList.add('scroll-fadeout');

    document.querySelector('.motion-topbar-logo-m').classList.remove('scroll-fadeout');
    document.querySelector('.motion-topbar-logo-m').classList.add('scroll-fadein');


    if (MotionViewport.isTop) {
      document.querySelector('.motion-topbar-logo').classList.remove('scroll-fadeout');
      document.querySelector('.motion-topbar-logo').classList.add('scroll-fadein');

      document.querySelector('.motion-topbar-logo-m').classList.remove('scroll-fadein');
      document.querySelector('.motion-topbar-logo-m').classList.add('scroll-fadeout');

    }

  });

}

export {topAppBar, drawer};
 
