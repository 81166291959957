const motion_lazyload = document.querySelectorAll('.lazyload'),
  motion_lazyload_elements = motion_lazyload.length;

if ( motion_lazyload_elements !== 0 ) {

    window.addEventListener("scroll", function () {
      let isInViewport = function (elmnt) {
        let bounding = elmnt.getBoundingClientRect();
        return (
          bounding.top >= 0 &&
          bounding.left >= 0 &&
          bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
          bounding.right <= (window.innerWidth || document.documentElement.clientWidth)
        );
      };

      for ( let i = 0; i < motion_lazyload_elements; i++ ) {
        if (isInViewport(motion_lazyload[i])) {
          if (motion_lazyload[i].getAttribute('data-src') && motion_lazyload[i].getAttribute('src') == null) {
            motion_lazyload[i].setAttribute('src', motion_lazyload[i].getAttribute('data-src'));
          }

          if (motion_lazyload[i].getAttribute('data-srcset') != null && motion_lazyload[i].getAttribute('srcset') == null) {
            motion_lazyload[i].setAttribute('srcset', motion_lazyload[i].getAttribute('data-srcset'));
          }

        }
      }


    });

}

export { motion_lazyload };
