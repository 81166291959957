import {createRandomString} from './string-generator'

/**
 * Check DOM if ID already exist
 *
 * @param {string} idToCheck
 * @returns {boolean}
 */
const isIdUnique = function( idToCheck ) {
  return ( document.getElementById( idToCheck ) === null );
};

/**
 * Generate a Unique ID
 *
 * @param {number} lowerLength =6
 * @param {number} upperLength =0
 * @param {number} numericLength =4
 * @param {string} customSeries =''
 * @param {number} customLength =6
 * @returns {string}
 */
const generateUnusedId = function( lowerLength = 6, upperLength = 0, numericLength = 4, customSeries = '', customLength = 6 ) {
  while ( true ) {
    let generatedId = createRandomString(lowerLength, upperLength, numericLength, customSeries, customLength );
    if ( isNaN( parseInt( generatedId[0] ) ) ) // only continue if string begins with an alpha character
      if ( isIdUnique( generatedId ) ) // only continue if ID doesn't exist already
        return generatedId;
  }
};

export { isIdUnique, generateUnusedId };
