import {MotionExpander} from './_base';
import {SlideAnimations} from "../animations/slide-animations";

class MotionAccordionExpander extends MotionExpander {

  constructor( container, index ) {
    super( container, index, 'accordion-expander' );
  }

  afterInit() {
    // Find the first a open item, then close the rest
    const open_on_init = this.contents.filter( item => { return item.classList.contains('open') }),
      open_on_init_length = open_on_init.length;

    if ( open_on_init_length > 1 )
      for ( let o = 1; o < open_on_init_length; o++ ) // note we start at [1]
        open_on_init[o].classList.remove('open');

    super.afterInit();
  }

  mousedownOnTrigger( event ) {
    // event.preventDefault();
    const this_trigger = event.currentTarget,
      this_item = this_trigger.parentNode,
      this_content = document.getElementById( this_item.getAttribute('id') + '_content' ); // getting element by ID is faster than by classname

    // If open, close it
    if ( this_content.classList.contains( 'open' ) ) {
      this.changeIndicatorIcon( this_trigger, 'expand_more' );

      this_item.classList.remove( 'mdc-expander__item--open' );
      SlideAnimations.slideUp( this_content, 350, () => {
        this_item.classList.remove('mdc-expander__item--open');
      } );

    } else {
      let item_currently_open = this_item.parentNode.getElementsByClassName('mdc-expander__item--open');

      // close the open item
      if ( item_currently_open.length !== 0 ) {
        item_currently_open = item_currently_open[0];
        this.changeIndicatorIcon( item_currently_open, 'expand_more' );

        SlideAnimations.slideUp( document.getElementById( item_currently_open.getAttribute('id') + '_content' ), 350, () => {
          item_currently_open.classList.remove('mdc-expander__item--open');
        } );
      }

      // open the clicked on item
      this.changeIndicatorIcon( this_trigger, 'expand_less' );
      SlideAnimations.slideDown(this_content, 350, () => {
        this_item.classList.add('mdc-expander__item--open');
      });
    }

  }

}

export { MotionAccordionExpander };
