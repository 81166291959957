const SlideAnimations = {

  /**
   * SlideUp
   *
   * @param {HTMLElement} element
   * @param {Number} duration
   * @param {Function|undefined} callback
   * @returns {Promise<boolean>}
   */
  slideUp: (element, duration = 500, callback = undefined ) => {

    return new Promise((resolve, reject) => {

      element.style.height = element.offsetHeight + 'px';
      element.style.transitionProperty = 'height, margin, padding';
      element.style.transitionDuration = duration + 'ms';
      element.offsetHeight;
      element.style.overflow = 'hidden';
      element.style.height = 0;
      element.style.paddingTop = 0;
      element.style.paddingBottom = 0;
      element.style.marginTop = 0;
      element.style.marginBottom = 0;

      window.setTimeout(() => {
        //element.style.display = 'none';
        element.classList.remove('open');
        element.style.removeProperty('height');
        element.style.removeProperty('padding-top');
        element.style.removeProperty('padding-bottom');
        element.style.removeProperty('margin-top');
        element.style.removeProperty('margin-bottom');
        element.style.removeProperty('overflow');
        element.style.removeProperty('transition-duration');
        element.style.removeProperty('transition-property');
        if ( typeof callback === 'function')
          callback('closed');
        resolve(false);
      }, duration)
    })
  },

  /**
   * SlideDown
   *
   * @param {HTMLElement} element
   * @param {Number} duration
   * @param {Function|undefined} callback
   * @returns {Promise<boolean>}
   */
  slideDown: ( element, duration = 500, callback = undefined ) => {

    return new Promise((resolve, reject) => {
      element.style.removeProperty('display');

      // let display = window.getComputedStyle(element).display;
      // if (display === 'none')
      //   display = 'block';
      // element.style.display = display;
      element.classList.add('open');

      let height = element.offsetHeight;
      element.style.overflow = 'hidden';
      element.style.height = 0;
      element.style.paddingTop = 0;
      element.style.paddingBottom = 0;
      element.style.marginTop = 0;
      element.style.marginBottom = 0;
      element.offsetHeight;
      element.style.transitionProperty = 'height, margin, padding';
      element.style.transitionDuration = duration + 'ms';
      element.style.height = height + 'px';
      element.style.removeProperty('padding-top');
      element.style.removeProperty('padding-bottom');
      element.style.removeProperty('margin-top');
      element.style.removeProperty('margin-bottom');

      window.setTimeout(() => {
        element.style.removeProperty('height');
        element.style.removeProperty('overflow');
        element.style.removeProperty('transition-duration');
        element.style.removeProperty('transition-property');
        if ( typeof callback === 'function')
          callback('open');
      }, duration)
    })
  },

  /**
   * SlideToggle
   *
   * @param {HTMLElement} element
   * @param {Number} duration
   * @param {Function|undefined} callback
   * @returns {Promise<boolean>}
   */
  slideToggle: function ( element, duration = 500, callback = undefined ) {
    if (window.getComputedStyle(element).display === 'none')
      return this.slideDown(element, duration, callback);
    else
      return this.slideUp(element, duration, callback);
  }
};

export { SlideAnimations };
