import {MotionRegularExpander} from './_regular';
import {MotionAccordionExpander} from './_accordion';

const initiateExpanders = ( global_object ) => {

  // Regular
  const regular_expanders = document.querySelectorAll('.mdc-expander.mdc-expander--regular'),
    regular_expanders_length = regular_expanders.length;

  if ( regular_expanders_length !== 0 ) {

    global_object.Expanders = global_object.Expanders || {};
    global_object.Expanders.regular = [];

    for( let r = 0; r < regular_expanders_length; r++ )
      global_object.Expanders.regular.push( new MotionRegularExpander( regular_expanders[r], r ) );

  }


  // Accordion
  const accordion_expanders = document.querySelectorAll('.mdc-expander.mdc-expander--accordion'),
    accordion_expanders_length = accordion_expanders.length;

  if ( accordion_expanders_length !== 0 ) {

    global_object.Expanders = global_object.Expanders || {};
    global_object.Expanders.accordion = [];

    for( let a = 0; a < accordion_expanders_length; a++ )
      global_object.Expanders.accordion.push( new MotionAccordionExpander( accordion_expanders[a], a ) );
  }

};

export { initiateExpanders };
