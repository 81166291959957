import { EasingEquations } from '../animations/easing-equations';

/**
 * Add 'requestAnimFrame' shim
 * @source https://stackoverflow.com/a/26808520
 * @source http://www.paulirish.com/2011/requestanimationframe-for-smart-animating/
 */
window.requestAnimFrame = (function(){
  return  window.requestAnimationFrame ||
    window.webkitRequestAnimationFrame ||
    window.mozRequestAnimationFrame    ||
    function( callback ){
      window.setTimeout(callback, 1000 / 60);
    };
})();

/**
 *
 * @param {number} scrollTargetY    Target scrollY property of the window
 * @param {number} speed            Time in pixels per second
 * @param {string} easeEquation     Easing equation to use
 */
const scrollToY = function( scrollTargetY = 0, speed = 2000, easeEquation = 'easeOutSine' ) {
  const scrollY = window.scrollY || document.documentElement.scrollTop;
  let currentTime = 0;

  // min time 100ms, max time 800ms
  const time = Math.max( 0.1, Math.min( Math.abs(scrollY - scrollTargetY) / speed, 0.8 ) );

  // add animation loop
  const tick = () => {
    currentTime += 1 / 60;

    const tickPosition = currentTime / time,
          tickTime = EasingEquations[easeEquation](tickPosition); // assuming the passed easeEquation is uncommented in the easing-equations.js file

    if (tickPosition < 1) {
      requestAnimFrame(tick);
      window.scrollTo(0, scrollY + ((scrollTargetY - scrollY) * tickTime));
    } else {
      //console.log('scroll done');
      window.scrollTo(0, scrollTargetY);
    }
  };

  // call it once to get started
  tick();
};

class MotionViewport {
  static get width() {
    return document.documentElement.clientWidth  || window.innerWidth;
  }
  static get height() {
    return document.documentElement.clientHeight || window.innerHeight;
  }

  static get scrollTop() {
    // Relative page top || All Modern browsers || IE;
    return document.documentElement.scrollTop || window.scrollY || window.pageYOffset;
  }

  static get isTop() {
    return MotionViewport.scrollTop === 0;
  }

  static get isBottom() {
    return ( MotionViewport.height + MotionViewport.scrollTop ) >= document.body.offsetHeight;
  }


  /**
   * Smooth scroll to top
   */
  static scrollToTop() {
    scrollToY(0, 1500, 'easeOutSine');

    // focus first element on page
    document.body.children[0].focus();
  }

  /**
   * Smooth scroll to bottom
   */
  static scrollToBottom() {
    const tempElId = 'motion-body-end-temp';

    // insert empty temp element before end of body tag
    document.body.insertAdjacentHTML('beforeEnd', `<div id="${tempElId}"></div>`);

    const tempElement = document.getElementById( tempElId );
    const bottomPosition = tempElement.offsetTop;

    // remove empty temp element
    tempElement.parentNode.removeChild( tempElement );

    scrollToY(bottomPosition, 1500, 'easeOutSine');

    // focus last element on page
    // last_page_element[0].focus();
  }

}

export { MotionViewport };
