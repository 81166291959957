/**
 * Attach single class object to multiple elements
 *
 * @param {string} querySelector
 * @param {*|object} objectName
 * @param {*} args
 */
function attachObjectToMultipleElements( querySelector, objectName, ...args ) {

    const selection = document.querySelectorAll( querySelector ) || [];
    const selection_length = selection.length;
    let iterator = 0;

    for ( ; iterator < selection_length; iterator++ )
      objectName.attachTo( selection[iterator], ...args );

}

// function attachMultipleEvents( querySelector, eventFunction ) {
//   const selection = document.querySelectorAll( querySelector );
//   const selection_length = selection.length;
//   let iterator = 0;
//
//   for ( ; iterator < selection_length; iterator++ )
//     selection[iterator].addEventListener( event => eventFunction(event) );
// }

export { attachObjectToMultipleElements };
