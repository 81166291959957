const motion_searchbar = document.querySelector('.motion-search-bar');

  if (motion_searchbar !== null) {

    document.querySelector('#motion-search').addEventListener('click', function () {
      document.querySelector('.motion-search-bar').classList.add('slidein-down');

      document.querySelector('#gsc-i-id1').setAttribute('placeholder','Search Momentum');

    });

    document.querySelector('#motion-search-close').addEventListener('click', function () {
      document.querySelector('.motion-search-bar').classList.remove('slidein-down');

    });

  }

export { motion_searchbar };
