/*
 * Merge properties of one object with another object
 *
 * @param {object}  defaultObject      Object with properties that will be the default values
 * @param {object}  mergerObject       Object with properties that will change the default when specified
 * @param {boolean} discardAdditional  Properties in mergerObject that are not in defaultObject will be discarded
 */
const mergeObjects = function (defaultObject, mergerObject, discardAdditional = false ) {

  //Return all properties of defaultObject as well as any others from mergerObject
  if( discardAdditional === false )
    for( const property in mergerObject )
      if( mergerObject.hasOwnProperty( property ) && mergerObject[ property ] !== undefined )
        defaultObject[ property ] = mergerObject[ property ];

      //Return all properties of defaultObject only
      else
        for( const property in defaultObject )
          if( defaultObject.hasOwnProperty( property ) && mergerObject[ property ] !== undefined )
            defaultObject[ property ] = mergerObject[ property ];

  return defaultObject;
};

/**
 * Add properties to an object
 *
 * @param {Object} target The target object onto which the properties should be added
 * @param {Array}  props  The functions to be added as properties of the object
 * @private
 */
const defineProperties = function (target, props) {
  for ( let i = 0, l = props.length; i < l; i++ ) {
    const descriptor = props[i];
    descriptor.enumerable = descriptor.enumerable || false;
    descriptor.configurable = descriptor.configurable || false;
    if ('value' in descriptor)
      descriptor.writable = true;
    Object.defineProperty(target, descriptor.key, descriptor);
  }
};


export { mergeObjects, defineProperties };
