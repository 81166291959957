import {attachObjectToMultipleElements} from "../../motion/utilities/attach-obj-to-elements";
import {MDCTabBar} from '@material/tab-bar'; // eslint-disable-line no-unused-vars
import {MotionViewport} from '../utilities/viewport';// eslint-disable-line no-unused-vars

const tabBarEl = document.querySelector('.mdc-tab-bar');

let mdc_tabBar = null;

if (tabBarEl !== null) {

  mdc_tabBar = attachObjectToMultipleElements('.mdc-tab-bar', MDCTabBar, true);

  if(tabBarEl.classList.contains('mdc-tab-sticky')) {
    window.addEventListener('scroll', function () {
      tabBarEl.classList.add('mdc-tab-bar--fixed', 'mdc-tab-bar--fixed-scrolled');
      tabBarEl.classList.remove('bb-1-motion-grey--light');

      if (MotionViewport.isTop) {
        tabBarEl.classList.remove('mdc-tab-bar--fixed', 'mdc-tab-bar--fixed-scrolled');
        tabBarEl.classList.add('bb-1-motion-grey--light');
      }

    });
  }

}

export { mdc_tabBar };
