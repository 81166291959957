import {MDCList} from '@material/list';
import {MDCRipple} from "@material/ripple/dist/mdc.ripple";
import {attachObjectToMultipleElements} from "../motion/utilities/attach-obj-to-elements";

const regular_list_elements = document.querySelector('.mdc-list');
// const interactive_list_elements = document.querySelector('.mdc-list--interactive');
let mdc_list = null;

if ( regular_list_elements !== null )
  mdc_list = new MDCList( regular_list_elements );

// Attach bounded ripple
attachObjectToMultipleElements([
  // '#motion-footer .mdc-list-item',
  '.mdc-list--interactive > .mdc-list-item:not(.mdc-list-item--disabled)',
].join(','), MDCRipple, false);

export { mdc_list };
