import {mergeObjects} from './objects';

class MotionEvents {
  /**
   * Register Event
   *
   * @param {String} eventType
   * @param {Object} properties
   *
   * @returns {Event}
   */
  static registerEvent ( eventType, properties = {} ) {
    properties = mergeObjects( { bubbles: false }, properties );
    return ( new Event( eventType, properties ) );
  }


  /**
   * Trigger Event
   *
   * Basic usage:
   *
   *   MotionEvents.triggerEvent( element, 'click' );
   *
   * @param {HTMLElement} element
   * @param {Event} eventObject
   *
   * @returns {boolean}
   */
  static triggerEvent ( element, eventObject ) {
    if( typeof eventObject === 'object' && eventObject instanceof Event )
      return ( element ).dispatchEvent( eventObject );

    else if( typeof eventObject === 'string' ) {
      const eventProperties = typeof arguments[2] === 'object' ? arguments[2] : {};
      return ( element ).dispatchEvent( MotionEvents.registerEvent( eventObject, eventProperties ) );
    }
  };

}

/**
 * Debounce
 *
 * Returns a function, that, as long as it continues to be invoked, will not be triggered.
 * The function will be called after it stops being called for N milliseconds.
 * If `immediate` is passed, trigger the function on the leading edge, instead of the trailing.
 *
 * @see https://davidwalsh.name/function-debounce
 *
 * Usage example
 *
 *   let updateLayout = debounce( function() {
   *     // all the taxing stuff
   *   }, 250 );
 *
 *   window.addEventListener('resize', updateLayout, false);
 *
 * @param {Function} func           Function to debounce
 * @param {number}   delay          Maximum run of once per so many milliseconds
 * @param {boolean}  immediate      Debounce immediately
 */
const debounce = function ( func, delay = 300, immediate = false ) {
  let timeout;

  return function() {
    const context = this,
          args = arguments;

    const later = function() {
      timeout = null;
      if ( ! immediate )
        func.apply(context, args);
    };

    const callNow = immediate && !timeout;
    clearTimeout( timeout );
    timeout = setTimeout( later, delay );

    if ( callNow )
      func.apply(context, args);
  };
};

/**
 * Check if browser supports an object with a 'passive' as a property as the third parameter of addEventListener
 * @source https://developer.mozilla.org/en-US/docs/Web/API/EventTarget/addEventListener
 *
 * IE_ONLY: remove this and all mentions of this when IE support is dropped
 */
const testPassiveEventSupport = function () {
  let passiveIsSupported = true;
  try {
    const options = Object.defineProperty({}, 'passive', {
      get: function() {
        passiveIsSupported = true;
      }
    });

    window.addEventListener('testpassive', options, options);
    window.removeEventListener('testpassive', options, options);
  } catch(err) {
    passiveIsSupported = false;
  }
  return passiveIsSupported;
};


/**
 * Prevent the space-bar from scrolling the page
 *
 * @param {HTMLElement} target
 */
const preventSpaceScroll = function ( target ) {
  window.addEventListener('keydown', event => {
    if ( event.keyCode === 32 && event.target === target )
      event.preventDefault();
  });
};

/**
 * Prevent the both home and end button from scrolling to the top and bottom of the page respectively
 * @param {HTMLElement} target
 */
const preventHomeEndScroll = function ( target ) {
  // 35 is END
  // 36 is HOME
  window.addEventListener('keydown', event => {
    if( event.target === target && ( event.keyCode === 35 || event.keyCode === 36 ) )
      event.preventDefault();
  });
};

export {
  MotionEvents,
  debounce,
  testPassiveEventSupport,
  preventHomeEndScroll,
  preventSpaceScroll,
};
