
const series_lowercase = 'abcdefghijklmnopqrstuvwxyz',
      series_uppercase = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ',
      series_numeric   = '0123456789';

/**
 * Return a random string
 *
 * @link http://stackoverflow.com/a/19964557/124222
 *
 * @param {string} character_series
 * @param {number} len =4
 * @param {number} lenLimit =16
 *
 * @return {string}
 */
const randomStringFromSeries = function( character_series, len = 4, lenLimit = 16 ) {
  let str = '';

  if(len > lenLimit)
    len = lenLimit;

  while ( str.length <= len ) {
    str += Array.apply(null, Array( 10 ))
      .map(() => {
        return character_series.charAt( Math.floor( Math.random() * character_series.length ) );
      }).join('');
  }
  return str.substr(0, len);
};

/**
 * Fisher-Yates (aka Knuth) Shuffle
 *
 * Adjusted for strings
 *
 * @link https://github.com/coolaj86/knuth-shuffle
 * @link http://stackoverflow.com/a/2450976/124222
 *
 * @param {string} originalString
 *
 * @return {string}
 */
const shuffleCharacters = function( originalString ) {
  let currentIndex = originalString.length;
  let shuffled = originalString.split('');

  // While there remain elements to shuffle...
  while ( 0 !== currentIndex ) {

    // Pick a remaining element...
    let randomIndex = Math.floor( Math.random() * currentIndex );
    currentIndex -= 1;

    // And swap it with the current element.
    let temp = shuffled[ currentIndex ];
    shuffled[ currentIndex ] = shuffled[ randomIndex ];
    shuffled[ randomIndex ] = temp;

  }
  return shuffled.join('');
};


/**
 * Make a random string using a selection of several libraries
 *
 * @param lowerLength
 * @param upperLength
 * @param numericLength
 * @param customSeries
 * @param customLength
 * @returns {string}
 */
const createRandomString = function( lowerLength = 2, upperLength = 2, numericLength = 2, customSeries = '', customLength = 6 ) {
  let workingString = '';

  if ( lowerLength >= 1 )
    workingString += randomStringFromSeries( series_lowercase, lowerLength );

  if ( lowerLength >= 1 )
    workingString += randomStringFromSeries( series_uppercase, upperLength );

  if ( numericLength >= 1 )
    workingString += randomStringFromSeries( series_numeric, numericLength );

  if ( customSeries !== '' && customSeries.length >= customLength )
    workingString += randomStringFromSeries( customSeries, customLength );

  return shuffleCharacters( workingString );
};

export { shuffleCharacters, createRandomString };

