import {MotionViewport} from '../utilities/viewport';// eslint-disable-line no-unused-vars


// var breadcrumbsEl = document.querySelector('#motion-breadcrumbs');
//
//
// window.addEventListener('scroll', function () {
//   breadcrumbsEl.classList.add('mdc-breadcrumbs--fixed-scrolled');
//   document.querySelector('.breadcrumb').classList.remove('bb-1-motion-grey--light');
//
//   // if (MotionViewport.isTop) {
//   //     breadcrumbsEl.classList.remove('mdc-tab-bar--fixed', 'mdc-tab-bar--fixed-scrolled');
//   //     breadcrumbsEl.classList.add('bb-1-motion-grey--light');
//   // }
//
// });



const motion_breadcrumbs = document.querySelector('#motion-breadcrumbs');


if (motion_breadcrumbs !== null) {

    window.addEventListener('scroll', function () {
      motion_breadcrumbs.classList.add('mdc-breadcrumbs--fixed-scrolled');
      document.querySelector('.breadcrumb').classList.remove('bb-1-motion-grey--light');
      motion_breadcrumbs.classList.add('bt-1-motion-grey--light');

      if (MotionViewport.isTop) {
        motion_breadcrumbs.classList.remove('mdc-breadcrumbs--fixed-scrolled');
        document.querySelector('.breadcrumb').classList.add('bb-1-motion-grey--light');
        motion_breadcrumbs.classList.remove('bt-1-motion-grey--light');
      }

    });

}

export { motion_breadcrumbs };
