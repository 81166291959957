import {attachObjectToMultipleElements} from "../motion/utilities/attach-obj-to-elements";
import {MDCRipple} from '@material/ripple';
import {MDCIconButtonToggle} from '@material/icon-button';
import {initiateExpanders} from '../motion/expander/expander'
import {topAppBar, drawer} from './navigation'; // eslint-disable-line no-unused-vars
import {mdc_list} from './list'; // eslint-disable-line no-unused-vars
import {mdc_tabBar} from '../motion/tabs/tabs'; // eslint-disable-line no-unused-vars
import {mdc_select} from './select'; // eslint-disable-line no-unused-vars
import {motion_searchbar} from "../motion/search/search"; // eslint-disable-line no-unused-vars
import {motion_breadcrumbs} from "../motion/breadcrumbs/breadcrumbs";  // eslint-disable-line no-unused-vars
import {motion_lazyload} from "./lazyload"; // eslint-disable-line no-unused-vars

// Bounded ripple
attachObjectToMultipleElements([
  '.mdc-button',
  '.mdc-card__primary-action',
  '.mdc-expander__trigger',
].join(','), MDCRipple, false);

// Unbounded Ripple
attachObjectToMultipleElements([
  '.mdc-ripple-upgraded.mdc-ripple-upgraded--unbounded',
].join(','), MDCRipple, true);

attachObjectToMultipleElements('.mdc-icon-button', MDCIconButtonToggle);

document.addEventListener("DOMContentLoaded", function() {
  initiateExpanders( window );
});



